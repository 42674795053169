import {
    type commentType, IssueStatus, MaterialType, type issue,
    Severity
} from '../../shared/shared.interface';
import { type temporaryIssue } from '../types/temporaryIssueData';
import type user from '../types/user';

const temporaryIssueObj: temporaryIssue = {
    address: '',
    severity: Severity.Low,
    cameraImage: [],
    beforeImage: [],
    afterImage: [],
    location: {
        coordinates: [ 0, 0 ]
    },
    mapURL: '',
    dateTaken: '',
    issueDescription: '',
    issueType: 'Pothole',
    fixTime: 0,
    issueStatus: IssueStatus.PENDING,
    userName: '',
    reporter: '',
    assignee: '',
    materialType: MaterialType.A01,
    quantityUsed: 0,
    diameter: 0,
    depth: 0,
    lat: 0,
    lng: 0,
    overlayImage: ''
};
export const useTemporaryIssue = () => {
    return useState<temporaryIssue>('temporaryIssue', () => temporaryIssueObj);
};
export const useIssues = () => {
    return useState<issue[] | undefined>('issues', () => [] || undefined);
};
export const useComments = () => {
    return useState<commentType[]>('comments', () => []);
};
export const useIsEditIssue = () => {
    return useState<boolean>('isEditissue', () => false);
};
export const useUser = () => {
    return useState<user>('user', () => ({
        name: '',
        email: '',
        avatar: ''
    }));
};
